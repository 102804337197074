import React, { useState } from 'react';
import {CircularProgress, Grid } from '@mui/material';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { IObjective } from '../../shared/types';
import { ObjectiveService } from '../../shared/services/api/objective/ObjectiveService';
import { useMentoringContext} from '../../shared/contexts';
import { DetailingObjectivesNewSample } from './DetailingObjectivesNewSample';
import { EASButton, EASSelect } from '../../shared/forms';
import { IList, UtilService } from '../../shared/services/api/util/UtilService';
import { useMutation, useQuery, useQueryClient } from 'react-query';

  
export const DetailingObjectivesNew = () => {

  const { enqueueSnackbar } = useSnackbar();

  const { mentory } = useMentoringContext();

  const queryClient = useQueryClient();
  
  const [textObjective, setTextObjective] = useState<string>('');
  const [textObjectiveError, setTextObjectiveError] = useState<string>('');

  const [objectiveSample, setObjectiveSample] = useState<string>();
  
  const ObjectiveSchema = yup.object().shape({
    textObjective: yup.string().required('Defina um Objetivo'),
  });

  const { data:objectiveSampleList } = useQuery(['list-objectives-sample'], () =>  UtilService.getObjectivesSample(), {
    staleTime: Infinity,
    
    onError: () => {
        enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
    },
  });


  const { mutateAsync:storeNewObjective, isLoading } = useMutation((objective:IObjective) => ObjectiveService.createObjective(objective), {
    onSuccess: () => {
      queryClient.invalidateQueries('objectives-list');
      queryClient.invalidateQueries('task-plan-detailings');
      enqueueSnackbar('Competência cadastrada com sucesso!', { variant:'success'});
      setTextObjective(''); 
      setTextObjectiveError('');
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar cadastrar a competência!', { variant:'error'});
    },
  });

  const handleButtonClick = () => {
      ObjectiveSchema
      .validate({ textObjective }, { abortEarly: false })
      .then(validateValues => {
        if(objectiveSampleList?.length && objectiveSampleList.findIndex(item => item.value === validateValues.textObjective) >= 0){
          setObjectiveSample(validateValues.textObjective);
         }
        else {
          const objective: IObjective = {
            id_objective: '',
            id_mentory: mentory?.id_mentory,
            status_objective: '0',
            text_objective: validateValues.textObjective,
            date_objective: new Date().toLocaleDateString('pt-BR'),
            sample_objective: '',
          }; 
          storeNewObjective(objective);
          setTextObjective('');
        }
      })
      .catch((errors: yup.ValidationError) => {
        errors.inner.forEach(error => {
          if (error.path === 'textObjective') {
            setTextObjectiveError(error.message);
          } 
        });
      });
    
  };

  return (
      <Grid container spacing={2} >
        <Grid item xs={12} >
          <EASSelect
              isLoading={isLoading}
              options={objectiveSampleList ? objectiveSampleList : []}
              getOptionLabel={(option) => option.text}
              value={objectiveSampleList && objectiveSampleList.find(item => item.value == textObjective)}                                  
              onChangeSelect={(event, value:IList) => {
                                if (value && value.value) {
                                    setTextObjective(value.value);
                                }
                              }}
              label="Competência"
              onChangeTextField={(event) => {
                setTextObjective(event.target.value);
              }}
              helperText={textObjectiveError}
              error={!!textObjectiveError}
              onFocus={() => setTextObjectiveError('')}
              fullWidth={true}
              freeSolo={true}
            />
        </Grid>
        <Grid item xs={12}>
          <EASButton 
            fullWidth 
            onClick={handleButtonClick} 
            variant='contained'
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <PlaylistAddIcon/>}
            >Adicionar</EASButton>
          <DetailingObjectivesNewSample objectiveSample={objectiveSample} saveObjectiveSample={(e) => storeNewObjective(e)} />
        </Grid>
      </Grid>
  );
};

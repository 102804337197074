import React, {useEffect, useState} from 'react';
import { Grid, Typography } from '@mui/material';

import { Welcome } from './Welcome';
import { DealsChart } from './DealsChart';
import { HotContacts } from './HotContacts';
import { LatestNotes } from './LatestNotes';
import { DealsPipeline } from './DealsPipeline';
import { EASDashboardCard, EASDashboardChartBar, EASDashboardChartBarH, EASDashboardChartGauge, EASDashboardLigthCard } from '../../shared/components/eas-dashboard-panels';
import { IDashboardResourcesHuman } from '../../shared/types';




interface EASProgressListProps {
    dashboardResourcesHuman?:IDashboardResourcesHuman;
    isLoading:boolean;
} 

export const DashboardResourcesHumanPanel: React.FC<EASProgressListProps> = ({dashboardResourcesHuman, isLoading}) => {


    return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Participantes"
                                icon="groups"
                                value={dashboardResourcesHuman?.cards && dashboardResourcesHuman?.cards[0]?.cardValue ? dashboardResourcesHuman.cards[0].cardValue : '0'}
                                subtitle={dashboardResourcesHuman?.cards && dashboardResourcesHuman?.cards[0]?.cardSubValue ? dashboardResourcesHuman.cards[0].cardSubValue : ''}
                                backgroundIcon="transparent"
                                colorIcon="rgb(255, 193, 7)"
                                opcityIcon="1"
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Total de Homens"
                                icon="man"
                                value={dashboardResourcesHuman?.cards && dashboardResourcesHuman?.cards[1]?.cardValue ? dashboardResourcesHuman.cards[1].cardValue : '0'}
                                subtitle={dashboardResourcesHuman?.cards && dashboardResourcesHuman?.cards[1]?.cardSubValue ? dashboardResourcesHuman.cards[1].cardSubValue : ''}
                                backgroundIcon="transparent"
                                colorIcon="rgb(33, 150, 243)"
                                opcityIcon="1"
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Total de Mulheres"
                                icon="woman"
                                value={dashboardResourcesHuman?.cards && dashboardResourcesHuman?.cards[2]?.cardValue ? dashboardResourcesHuman.cards[2].cardValue : '0'}
                                subtitle={dashboardResourcesHuman?.cards && dashboardResourcesHuman?.cards[2]?.cardSubValue ? dashboardResourcesHuman.cards[2].cardSubValue : ''}
                                backgroundIcon="transparent"
                                colorIcon="rgb(153 33 243)"
                                opcityIcon="1"
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Competências Trabalhadas"
                                icon="crisis_alert"
                                value={dashboardResourcesHuman?.cards && dashboardResourcesHuman?.cards[3]?.cardValue ? dashboardResourcesHuman.cards[3].cardValue : '0'}
                                subtitle={dashboardResourcesHuman?.cards && dashboardResourcesHuman?.cards[3]?.cardSubValue ? dashboardResourcesHuman.cards[3].cardSubValue : ''}
                                backgroundIcon="transparent"
                                colorIcon="rgb(0, 200, 83)"
                                opcityIcon="1"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <EASDashboardChartGauge 
                                        isLoading={isLoading}
                                        title="Competências"
                                        description="Percentual de duplas que cadastraram uma ou mais competências, comportamentos, notas e prioridades."
                                        value={dashboardResourcesHuman?.progress && dashboardResourcesHuman?.progress[0] ? dashboardResourcesHuman.progress[0] : '0'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <EASDashboardChartGauge 
                                        isLoading={isLoading}
                                        title="Plano de Tarefas"
                                        description="Percentual de duplas que cadastraram uma ou mais atividades no plano de tarefas."
                                        value={dashboardResourcesHuman?.progress && dashboardResourcesHuman?.progress[1] ? dashboardResourcesHuman.progress[1] : '0'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <EASDashboardChartGauge 
                                        isLoading={isLoading}
                                        title="Diário de Aprendizagem"
                                        description="Percentual de duplas que cadastraram uma ou mais atividades no diário de aprendizagem."
                                        value={dashboardResourcesHuman?.progress && dashboardResourcesHuman?.progress[2] ? dashboardResourcesHuman.progress[2] : '0'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <EASDashboardChartGauge 
                                        isLoading={isLoading}
                                        title="Autoavaliações"
                                        description="Percentual de duplas que realizaram uma ou mais avaliações."
                                        value={dashboardResourcesHuman?.progress && dashboardResourcesHuman?.progress[3] ? dashboardResourcesHuman.progress[3] : '0'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/** 
                        <Grid item xs={12} md={8}>
                            <EASDashboardChartBar 
                                title="Acessos"
                            />
                        </Grid>
                        */}
                        <Grid item xs={12} md={4}>
                            <EASDashboardChartBarH 
                                title="Competências populares"
                                isLoading={isLoading}
                                values={dashboardResourcesHuman?.chart_objectives ? dashboardResourcesHuman.chart_objectives : []}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);
};

import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { orange, blue, green } from '@mui/material/colors';
import { IChartObjectiveItems } from '../../types';


interface IEASDashboardChartBarHProps  {
  isLoading?:boolean;
  title?:string;
  subtitle?:string;
  description?:string;
  color?:string;
  background?:string;
  values?: Array<IChartObjectiveItems>;
}

export const EASDashboardChartBarH:React.FC<IEASDashboardChartBarHProps> = ({ ...props }) => {

  const option = {

      dataset: {
        source: props?.values

      },
      grid: { containLabel: true },
      xAxis: {  type: 'value',  minInterval: 1 },
      yAxis: { type: 'category' },

      series: [
        {
          type: 'bar',
          encode: {
            // Map the "amount" column to X axis.
            x: 0,
            // Map the "product" column to Y axis
            y: 1
          }
        }
      ]
      
      };

    return (
        <Card sx={{border:'8px', background:props.background, color:props.color, position:'relative'}}>
            <CardContent sx={{p:'24px'}}>
                <Typography variant="h4" sx={{ fontSize: '1rem', fontWeight: 600, lineHeight: 1.235}}>{props.title}</Typography>
                <Typography variant="h6" sx={{ fontSize: '0.75rem', lineheight:'1.57'}}>{props.subtitle}</Typography>
                <Box sx={{ maxHeight:'850px'}}>
                    <ReactEcharts style={{width:'100%'}}
                        option={{
                            ...option,
                        }}
                    />
                </Box>
                <Box sx={{height:'60px'}}>
                    <Typography variant="h6" sx={{ fontSize: '0.75rem', lineheight:'1.57'}}>{props.description}</Typography>
                </Box>
            </CardContent>
        </Card>
    );
};




import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IPresentation, IPresentationFinal } from '../../../types';

interface IPresentationService {
  id?: string;
}

interface IPresentationListResponse extends IPresentation {
  total?: number | null;
  data?: IPresentation | null;
}


const createPresentation = async (presentationFinal: IPresentationFinal): Promise<IPresentationService | Error> => {
  try {
    const { data } = await Api.post('/presentation', { presentationFinal: presentationFinal } );
  
    if (data) 
      return data;

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updatePresentation = async (presentationFinal: IPresentationFinal): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/presentation/${presentationFinal.id_presentation_final}`, presentationFinal);
    
    if (data) 
      return data;  

  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deletePresentation = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/presentation/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getPresentation = async (userEnrollment: string, client_id: string): Promise<IPresentationService | Error> => {
  try {
    const { data } = await Api.post('/objectivedetail', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getPresentations = async (id_mentory = '', filterObjective = 0, page = 1, filter = '' ): Promise<IPresentationListResponse | Error> => {
  try {
    if(!id_mentory)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/presentation?public_token=${Environment.PUBLIC_TOKEN}&mentory=${id_mentory}&f=${filterObjective}&page=${page}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const PresentationService = {
  getPresentations,
  getPresentation,
  createPresentation,
  updatePresentation,
  deletePresentation,
};

import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IMaintenancePlan, IMaintenancePlanLesson } from '../../../types';

interface IMaintenancePlanService {
  Teams?: string;
}

interface IMaintenancePlanListResponse {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: IMaintenancePlan | null;
}



const createMaintenancePlanLesson = async (maintenancePlanLesson: IMaintenancePlanLesson): Promise<void | Error> => {
  try {
    const { data } = await Api.post('/maintenanceplan', { maintenancePlanLesson: maintenancePlanLesson } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const createMaintenancePlan = async (maintenancePlan: IMaintenancePlan): Promise<void | Error> => {
  try {
    const { data } = await Api.post('/maintenanceplan', { maintenancePlan: maintenancePlan } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateMaintenancePlanLesson = async (maintenancePlanLesson: IMaintenancePlanLesson): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/maintenanceplan/${maintenancePlanLesson.id_maintenance_plan_lesson}`, maintenancePlanLesson);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateMaintenancePlan = async (maintenancePlan: IMaintenancePlan): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/maintenanceplan/${maintenancePlan.id_maintenance_plan}`, maintenancePlan);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const deleteMaintenancePlan = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/maintenanceplan/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getMaintenancePlan = async (id_mentory = ''): Promise<IMaintenancePlan | Error> => {
  try {
    if(!id_mentory)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/maintenanceplan?public_token=${Environment.PUBLIC_TOKEN}&mentory=${id_mentory}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const MaintenancePlanService = {
  getMaintenancePlan,
  createMaintenancePlan,
  updateMaintenancePlan,
  deleteMaintenancePlan,

  createMaintenancePlanLesson,
  updateMaintenancePlanLesson,

};


export const Environment = {
  VERSION: '1.0.0',

  LIMITE_DE_LINHAS: 20,

  LISTAGEM_VAZIA: 'Nenhum registro encontrado.',
  INPUT_DE_BUSCA: 'Pesquisar...',

  CLIENT_NAME: 'Aura Ex O - Mentoring',
  LANGUAGE: 'pt-BR',

  PUBLIC_TOKEN: 'dfcb2be4543cfbe361d0a86e67e215c0003365ae',
  CLIENT_ID: 'ef4eec5b4df1ad3538241efdcc0ab4423212036b',

  AMBIENT: '1', //0 - sandbox  | 1 - production

  API_URL: 'https://api.auraexo.com/',
  URL_CDN: 'https://cdn.auraexo.com/',
  API_FOLDER: 'v1',

  API_URL_SANDBOX: 'http://api.auraexo.eas.br/',
  URL_CDN_SANDBOX: 'http://cdn.auraexo.eas.br/',
  API_FOLDER_SANDBOX: 'v1',
};

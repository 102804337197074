import React from 'react';
import { Heading, Slide, Box, Quote } from 'spectacle';
import { Paper } from '@mui/material';
import { IMaintenancePlanLesson } from '../../shared/types';

interface IPresentationResourcesHumanFinalMaintencePlanLessonProps {
  key: number;
  lessons: Array<IMaintenancePlanLesson>;
  primary: string;
  background: string;
}


export const PresentationResourcesHumanFinalMaintencePlanLesson: React.FC<IPresentationResourcesHumanFinalMaintencePlanLessonProps> = ({ key, lessons, primary, background }) => {

  return (
    <Slide key={key} backgroundColor={background}>
      <Heading fontWeight="600" fontSize="28px" margin="0px" color="#000000">
        Quais foram os aprendizados que representam uma mudança de mindset?
      </Heading>
      <Heading fontWeight="400" fontStyle="italic" fontSize="22px" lineHeight="15px" padding="0px" margin="0px" color="#000000">
        Um Novo Jeito de Perceber as Situações
      </Heading>
      <Paper elevation={2} sx={{ position: 'absolute', width: '1000px', height: '520px', left: '200px', top: '150px', borderRadius: '12px' }}>
        <Box backgroundColor="trasnparent"
          position="absolute"
          top="20px"
          left="20px"
          width="940px"
          height="480px"
          style={{ flexDirection: 'column', justifyContent: 'center' }}
          display="flex">
          {(lessons &&
            lessons.length) &&
            (lessons.map((textLessons, index) => (


              <Box key={index} backgroundColor="trasnparent"
                verticalAlign="center">
                <Quote fontWeight="200"
                  fontSize="18px"
                  margin="auto 0"
                  color="#000000"
                  marginBottom="20px"
                >
                  {textLessons.text_maintenance_plan_lesson}
                </Quote>
              </Box>
            )))}
        </Box>
      </Paper>
    </Slide>
  );

};

import { IResponseRecoverPassword, IRecoverPassword } from '../../../types';
import { Api } from '../axios-config';

const recoverPassword = async (email?: string): Promise<IResponseRecoverPassword> => {
    const { data } = await Api.post('/recoverpassword', { email: email });
    return data;
};

const codeRecoverPassword = async (recoverpassword: IRecoverPassword): Promise<IResponseRecoverPassword> => {
    const { data } = await Api.put(`/recoverpassword/${recoverpassword.id}`, { recoverpassword: recoverpassword });
    return data;
};

export const RecoverPasswordService = {
    recoverPassword,
    codeRecoverPassword
};
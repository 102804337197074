import { useState, useEffect } from 'react';
import { CircularProgress, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid }from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { IObjective} from '../../shared/types';
import { ObjectiveService } from '../../shared/services/api/objective/ObjectiveService';
import { EASButton, EASInput } from '../../shared/forms';
import { useMutation, useQueryClient } from 'react-query';

interface IDetailingObjectivesEditProps {
  objective: IObjective | null;
  open: boolean;
  setOpen: (open:boolean) => void;
}

export const DetailingObjectivesEdit: React.FC<IDetailingObjectivesEditProps> = ({ open, setOpen, objective }) => {
  

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);



  const [objectiveEdit, setObjectiveEdit] = useState<IObjective>({});
  const [objectiveEditError, setObjectiveEditError] = useState<IObjective | null>(null);


  const objectiveEditSchema = yup.object().shape({
    text_objective: yup.string().required('Escreva a Competência'),
  });


  const { mutateAsync:storeUpdateObjective } = useMutation((Objective:IObjective) =>   ObjectiveService.updateObjective(Objective), {
    onSuccess: () => {
      queryClient.invalidateQueries('objectives-detail-list');
      queryClient.invalidateQueries('task-plan-detailings');
      queryClient.invalidateQueries('objectives-list');
      enqueueSnackbar('Competência atualizada com sucesso.', { variant:'success'});
      setLoadingDialog(false);
      setObjectiveEdit({});
      setObjectiveEditError(null);
      setOpen(false);
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar atualizar o competência!', { variant:'error'});
      setLoadingDialog(false);
    },
  });


  useEffect(() => {
    async function loadEditObjectiveDetail() {
      setIsLoading(true);
      if(objective)
        setObjectiveEdit(objective);      
    }
    if(open==true)
      loadEditObjectiveDetail().then(e => {
        setIsLoading(false);
      });

  }, [open]);


  const dialogSave = () => {
    objectiveEditSchema
    .validate(objectiveEdit, { abortEarly: false })
    .then(validateEditValues => {
      setLoadingDialog(true);
      const updateObjective: IObjective = {
        id_objective: objectiveEdit.id_objective,
        text_objective: validateEditValues.text_objective,
      };
      storeUpdateObjective(updateObjective);

    })
    .catch((errors: yup.ValidationError) => {
      errors.inner.forEach(error => {
        if(error.path){
            const keyError = error.path;                    
            setObjectiveEditError(existingValues => ({
                                    ...existingValues,
                                    [keyError]: error.message
                                }));                        
        }
      });
    });
  };

  return (
    <Dialog 
    open={open}
    keepMounted
    onClose={() => setOpen(false)}
    aria-describedby="alert-dialog-slide-description"
    fullWidth
    maxWidth="md"
    >
      <DialogTitle>{'Editar Competência'}</DialogTitle>
      <DialogContent>
        {isLoading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!isLoading && (
        <Box style={{ justifyContent: 'center', padding:'8px 0 0 8px' }}>
          <Grid sx={{width:'100%'}} container spacing={2}>
            <Grid item xs={12}>
              <EASInput
                label="Competência"
                value={objectiveEdit?.text_objective}                
                onChange={(e) => setObjectiveEdit(existingValues => ({
                                                        ...existingValues,
                                                        text_objective: e.target.value,
                                                      }))}
                onFocus={() => setObjectiveEditError(null)}
                fullWidth
                error={!!objectiveEditError?.text_objective}
                helperText={objectiveEditError?.text_objective}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
              />    
            </Grid> 
          </Grid>
        </Box>  
        )}
        </DialogContent>
        <DialogActions sx={{mr:4, mb:1}}>
          <EASButton disabled={loadingDialog} onClick={() => setOpen(false)} variant="outlined">Cancelar</EASButton>
          <EASButton disabled={loadingDialog}  onClick={dialogSave} variant="contained" autoFocus>Salvar</EASButton>
        </DialogActions>
    </Dialog>
  );
};

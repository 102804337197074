import React from 'react';

import { Heading, Slide, Text, Box, Quote } from 'spectacle';


import { Paper } from '@mui/material';
interface IPresentationResourcesHumanFinalTaskPlanProps {
  key: number;
  taskPlans: Array<string>;
  primary: string;
  background: string;
}


export const PresentationResourcesHumanFinalTaskPlan: React.FC<IPresentationResourcesHumanFinalTaskPlanProps> = ({ key, taskPlans, primary, background }) => {

  return (
    <Slide key={key} backgroundColor={background}>
      <Heading fontWeight="400" fontSize="36px" margin="0px" color="#000000">
        AÇÕES EXECUTADAS QUE EVIDENCIAM A NOTA FINAL DOS INDICADORES PRIORIZADOS NO DETALHAMENTO DE OBJETIVOS
      </Heading>
      <Box backgroundColor="trasnparent"
        position="absolute"
        top="150px"
        left="250px"
        width="900px">

        {(taskPlans &&
          taskPlans.length) &&
          (taskPlans.map((textTaskPlan, index) => (

            <Paper key={index} elevation={2} sx={{ position: 'absolute', width: '800px', height: '180px', left: '20px', top: (40 + (index * 200)) + 'px', borderRadius: '12px' }}>
              <Box backgroundColor="trasnparent"
                position="absolute"
                top="20px"
                left="20px"
                width="750px"
                height="140px">
                <Box backgroundColor="trasnparent"
                  verticalAlign="center"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <Quote fontWeight="200"
                    fontSize="18px"
                    margin="auto 0"
                    color="#000000"
                  >
                    {textTaskPlan}
                  </Quote>
                </Box>
              </Box>
            </Paper>
          )))}

      </Box>
    </Slide>
  );

};

import React, { useState } from 'react';
import { Grid, Box, Paper, Typography, Theme, useMediaQuery, CircularProgress  } from '@mui/material/';

import { green } from '@mui/material/colors';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { IObjectiveDetail } from '../../shared/types';
import { ObjectiveDetailService } from '../../shared/services/api/objective-detail/ObjectiveDetailService';
import { EASButton, EASInput } from '../../shared/forms';
import { Stack } from '@mui/system';
import { useMutation, useQueryClient } from 'react-query';
import { useMentoringContext } from '../../shared/contexts';

interface IDetailingObjectivesDetailNewProps {
  id_objective?: string;
}  

export const DetailingObjectivesDetailNew: React.FC<IDetailingObjectivesDetailNewProps> = ({id_objective}) => {

  const { mentory } = useMentoringContext();

  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  
  const [saveLoading, setSaveLoading] = useState<boolean>(false);


  const [objectiveDetail, setObjectiveDetail] = useState<IObjectiveDetail>({});
  const [objectiveDetailError, setObjectiveDetailError] = useState<IObjectiveDetail | null>(null);

  const ObjectiveDetail = yup.object().shape({
    text_objective_detail: yup.string().required('Detalhe uma Excelência'),
    value_objective_detail: yup.string().required('Defina sua Nota.'),
  });

  const { mutateAsync:storeCreate, isLoading} = useMutation((newObjectiveDetail:IObjectiveDetail) =>  ObjectiveDetailService.createObjectiveDetail(newObjectiveDetail), {
    onSuccess: () => {
        queryClient.invalidateQueries('objectives-detail-list');
        queryClient.invalidateQueries('objectives-detail-edit');
        queryClient.invalidateQueries('task-plan-detailings');
        enqueueSnackbar('Detalhamento da competência cadastrada com sucesso!', { variant:'success'});
        setSaveLoading(false);
        setObjectiveDetail({});

    },
    onError: () => {
        enqueueSnackbar('Erro ao cadastrar detalhamento nesta competência.', { variant:'error'});
        setSaveLoading(false);
    },
});



  const handleButtonClick = (id_objective?:string) => {
      if(id_objective) {
        ObjectiveDetail
        .validate(objectiveDetail, { abortEarly: false })
        .then(validateValues => {
          setSaveLoading(false);
          const newObjectiveDetail: IObjectiveDetail = {
            id_objective_detail:'',
            id_objective: id_objective,
            status_objective_detail: '1',
            text_objective_detail: validateValues.text_objective_detail,
            value_objective_detail: validateValues.value_objective_detail,
            priority_objective_detail: '0',
            date_objective_detail: new Date().toLocaleDateString('pt-BR'),
          };      
          storeCreate(newObjectiveDetail);
        })
        .catch((errors: yup.ValidationError) => {
          errors.inner.forEach(error => {
              if(error.path){
                  const keyError = error.path;                    
                  setObjectiveDetailError(existingValues => ({
                                      ...existingValues,
                                      [keyError]: error.message
                                  }));                        
              }
          });
        });
      }
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px'}}>   
      <Paper style={{ padding: '0.5em 0', width:'100%', textAlign:'center' }} elevation={0}>
        <Typography component="h4" variant="subtitle1" sx={{fontWeight:500}}>
          Adicione comportamentos observáveis nessa competência
        </Typography>
        <Typography component="h4" variant="subtitle2" sx={{fontWeight:10}}>
          Detalhe comportamentos que uma pessoa excelente nessa competência teria e faça sua autoavaliação, atribuindo uma nota ao seu desempenho nesses itens atualmente.
        </Typography>  
        <Grid container sx={{mt: 1}} spacing={1}>
          <Grid item xs={12} sx={{p:2}}>
            <Stack direction="row" spacing={1} sx={{display:'flex'}}>
            <EASInput
              onFocus={() => 
                setObjectiveDetail(existingValues => ({
                  ...existingValues,
                  text_objective_detail: '',
                }))
              }
              label={!xsDown && !smDown && !mdDown ? ('Escreva aqui o que uma pessoa excelente nesta competência faria'):('Comportamento ')}
              type="text"
              value={objectiveDetail?.text_objective_detail}
              onChange={(e) => 
                setObjectiveDetail(existingValues => ({
                  ...existingValues,
                  text_objective_detail: e.target.value,
                }))
              }
              
              error={!!objectiveDetailError?.text_objective_detail}
              helperText={objectiveDetailError?.text_objective_detail}
            />
            <EASInput
              onFocus={() => 
                setObjectiveDetail(existingValues => ({
                  ...existingValues,
                  value_objective_detail: '',
                }))
              }
              label={!xsDown && !smDown && !mdDown ? ('Sua nota') : ('Nota')}
              type="number"
              min={mentory?.min_grade_mentory ? mentory.min_grade_mentory : '0'}
              max={mentory?.max_grade_mentory ? mentory.max_grade_mentory : '10'}
              step={mentory?.step_grade_mentory ? mentory.step_grade_mentory : '1'}
              value={objectiveDetail?.value_objective_detail}
              onChange={(e) => {
                  const min = mentory?.min_grade_mentory ? mentory.min_grade_mentory : '0';
                  const max = mentory?.max_grade_mentory ? mentory.max_grade_mentory : '10';
                  setObjectiveDetail(existingValues => ({
                    ...existingValues,
                    value_objective_detail: (parseFloat(e.target.value) > parseFloat(max) ? max : (parseFloat(e.target.value) < parseFloat(min) ? min : e.target.value))
                  }));
                }
              }
              sx={{width:'120px'}}
              error={!!objectiveDetailError?.value_objective_detail}
              helperText={objectiveDetailError?.value_objective_detail}
            />
              <EASButton 
                variant='contained' 
                sx={{width:'180px', height: '50px'}}             
                disabled={saveLoading}
                // eslint-disable-next-line react/jsx-no-undef
                endIcon={saveLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <PlaylistAddIcon/>}
                onClick={() => handleButtonClick(id_objective)}
                >Adicionar</EASButton>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
    );
  };

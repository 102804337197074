import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext, useDrawerContext } from '../shared/contexts';
import {
  Dashboard,
  Mentoring,
  Mentory,
  Objectives,
  TaskPlan,
  Learning,
  Tools,
  ToolsDetail,
  /* ClosingMeeting,
   ResultsPresentation,*/
  Videos,
  Video,
  Tips,
  Files,
  File,
  People,
  Person,
  Companies,
  Company,
  Operators,
  Operator,
  Programs,
  Program,
  Ratings,
  Rating,
  Tags,
  ObjectivesSample,
  ObjectiveSample,
  Profile

} from '../pages';


export const IsAuthOperatorsRoutes = () => {

  const { user } = useAuthContext();
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {


    const MenuItens = [{
      icon: 'home',
      path: '/pagina-inicial',
      label: 'Página inicial',
      group: 'Principal',
    },
    {
      icon: 'group',
      path: '/processos',
      label: 'Processos',
    },
    {
      divider: true,
      group: 'Cadastros',
      icon: 'person',
      path: '/pessoas',
      label: 'Pessoas',
    },
    {
      icon: 'school',
      path: '/programas',
      label: 'Programas',
    },
    {
      icon: 'business',
      path: '/empresas',
      label: 'Empresas',
    },
    {
      icon: 'manage_accounts',
      path: '/operadores',
      label: 'Operadores',
    },
    {
      divider: true,
      group: 'Materiais e Apoio',
      icon: 'inventory',
      path: '/arquivos',
      label: 'Arquivos',
    },
    {
      icon: 'ondemand_video',
      path: '/videos',
      label: 'Vídeos',
    },
    {
      icon: 'crisis_alert',
      path: '/acoes-sugeridas',
      label: 'Ações Sugeridas',
    },
    {
      divider: true,
      group: 'Miscelâneas',
      icon: 'request_quote',
      path: '/pesquisas-nps',
      label: 'NPS',
    },
    {
      icon: 'sell',
      path: '/tags',
      label: 'Tags',
    },
      /* {
          divider: true,
          group: 'Financeiro',
          icon: 'request_quote',
          path: '/pagamentos',
          label: 'Pagamentos',
        },
        {
          icon: 'receipt',
          path: '/notas',
          label: 'Notas',
        }*/
    ];


    setDrawerOptions(MenuItens);


  }, []);

  return (
    <Routes>
      <Route path="/editar-perfil" element={<Profile />} />
      <Route path="/pagina-inicial" element={<Dashboard />} />

      <Route path="/processos" element={<Mentoring />} />
      <Route path="/processos/detalhes/:id" element={<Mentory />} />

      <Route path="/pessoas" element={<People />} />
      <Route path="/pessoas/detalhes/:id" element={<Person />} />

      <Route path="/programas" element={<Programs />} />
      <Route path="/programas/detalhes/:id" element={<Program />} />

      <Route path="/empresas" element={<Companies />} />
      <Route path="/empresas/detalhes/:id" element={<Company />} />

      <Route path="/operadores" element={<Operators />} />
      <Route path="/operadores/detalhes/:id" element={<Operator />} />

      <Route path="/arquivos" element={<Files />} />

      <Route path="/arquivos/detalhes/:id" element={<File />} />

      <Route path="/videos" element={<Videos />} />
      <Route path="/videos/detalhes/:id" element={<Video />} />


      <Route path="/acoes-sugeridas" element={<ObjectivesSample />} />
      <Route path="/acoes-sugeridas/detalhes/:id" element={<ObjectiveSample />} />




      {/*
      <Route path="/videos" element={<Videos />} />
      <Route path="/videos/detalhes/:id" element={<Video />} />

      <Route path="/Competências" element={<SampleObjectives />} />
      <Route path="/Competências/detalhes/:id" element={<SampleObjective />} />


      <Route path="/apresentacoes/reuniao-de-encerramento" element={<ClosingMeeting />} />
      <Route path="/apresentacoes/apresentacao-dos-resultados" element={<ResultsPresentation />} />
      <Route path="/avaliacao-final" element={<EvaluationFinal />} />
      <Route path="/apresentacoes" element={<Presentations />} />


  */}

      <Route path="/pesquisas-nps" element={<Ratings />} />
      <Route path="/pesquisas-nps/detalhes/:id" element={<Rating />} />



      <Route path="/detalhamento-de-objetivos" element={<Objectives />} />
      <Route path="/plano-de-tarefas" element={<TaskPlan />} />
      <Route path="/diario-de-aprendizagem" element={<Learning />} />
      <Route path="/ferramentas-auxiliares" element={<Tools />} />
      <Route path="/ferramentas-auxiliares/influenciadores-de-reputacao" element={<ToolsDetail />} />
      <Route path="/ferramentas-auxiliares/mapa-de-proximidade" element={<ToolsDetail />} />
      <Route path="/ferramentas-auxiliares/mapeamento-dos-rotulos" element={<ToolsDetail />} />
      <Route path="/ferramentas-auxiliares/score-dos-influneciadores" element={<ToolsDetail />} />


      <Route path="/dicas" element={<Tips />} />

      <Route path="/tags" element={<Tags />} />
      {/*<Route path="/arquivos" element={<Archives />} />*/}

      <Route path="*" element={<Navigate to="/pagina-inicial" />} />


    </Routes>
  );
};

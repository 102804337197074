
import React, { useEffect, useState } from 'react';
import { Grid, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Skeleton, Typography, Chip } from '@mui/material';


import { IMentory } from '../../shared/contexts/MentoringContext/types';
import { IObjectiveDetail } from '../../shared/types';
import { ITaskPlan } from '../../shared/contexts/TaskPlanContext/types';
import { TaskPlanService } from '../../shared/services/api/task-plan/TaskPlanService';


type IDetailingDialogTaskPlanProps = {
    open: boolean;
    objectiveDetail:IObjectiveDetail | null ;
    mentory?:IMentory | null ;
    setOpen: (open:boolean) => void;
};

export const DetailingDialogTaskPlan:React.FC<IDetailingDialogTaskPlanProps> =  (({open, objectiveDetail, mentory, setOpen}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [allTaskPlan, setAllTaskPlan] = useState<Array<ITaskPlan>>([]);

    const handleClose = () => {
        setOpen(false);
    };
  
/*
    const { data, isLoading } = useQuery(['operator-company-list', [search, page]],() =>  TaskPlanService.getAllTaskPlan(mentory?.id_mentory, search), {
        staleTime: Infinity,
        onError: () => {
          enqueueSnackbar('Ocorreu um problema carregar a lista de Empresas.', { variant:'error'});
        },
    });

    const { mutateAsync:storeBlockCompany } = useMutation((blockCompany:ICompany) =>  CompaniesService.updateCompany(blockCompany), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-company-list');
            enqueueSnackbar('Empresa ' + (blockText ? 'desbloqueada' : 'bloqueada') + ' com sucesso!', { variant:'success'});
            setLoadingDialog(false);
            handleCancelDialog();
        },
        onError: () => {
            enqueueSnackbar('Erro ao tentar ' + (blockText ? 'desbloquear' : 'bloquear') + ' a empresa!', { variant:'error'});
        },
    });
*/

    useEffect(() => {
        async function getLists() {
            setIsLoading(true);
            if(objectiveDetail?.id_objective) {
                const TaskPlanResponse = await TaskPlanService.getAllTaskPlan(mentory?.id_mentory, 2, 0, objectiveDetail?.id_objective_detail);
                if (TaskPlanResponse instanceof Error) {
                    setAllTaskPlan([]);
                    setIsLoading(false);
                } else {
                    if(TaskPlanResponse && TaskPlanResponse.data){
                        setAllTaskPlan(TaskPlanResponse.data);
                        setIsLoading(false);
                    }
                    else{
                        setAllTaskPlan([]);
                        setIsLoading(false);
                    }
                }
            }
        }
        if(open==true)
            getLists();
    }, [open]);


    return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>{'Tarefas Associadas'}</DialogTitle>
        <Divider/>
            <DialogContent>
                <TableContainer sx={{ maxHeight: 'calc(50vh - 70px)'}}>
                    <Table  sx={{width: '100%'}} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Tarefa
                                </TableCell>
                                <TableCell component="th" scope="row"  align="center" width={'20%'}>
                                    Data
                                </TableCell>
                                <TableCell component="th" scope="row" align="center" width={'20%'}>
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && (
                            <>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                            </>
                            )}
                            {!isLoading && ( (allTaskPlan && allTaskPlan.length) ? (allTaskPlan.map((taskPlan) => (
                                
                            <TableRow 
                                hover 
                                key={taskPlan.id_task_plan} 
                                tabIndex={-1} 
                                role="checkbox"
                            >
                                <TableCell scope="row">
                                    {taskPlan.text_task_plan}
                                </TableCell>
                                <TableCell >
                                    <Grid container spacing={0} >
                                        <Grid item xs={12}>
                                            <Typography variant="caption" sx={{fontWeight:600}}>Registrado: { taskPlan?.date_register && new Date(taskPlan?.date_register).toLocaleDateString('pt-BR')}</Typography>
                                        </Grid>
                                        <Grid item  xs={12}>
                                            <Typography variant="caption" sx={{fontWeight:600}}>Previsto: { taskPlan?.date_task_plan && new Date(taskPlan?.date_task_plan).toLocaleDateString('pt-BR')}</Typography>
                                        </Grid>
                                        <Grid item  xs={12}>
                                            <Typography variant="caption" sx={{fontWeight:600}}>Conclisão: { taskPlan?.finish_task_plan && new Date(taskPlan?.finish_task_plan).toLocaleDateString('pt-BR')}</Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell align="center">
                                <Chip 
                                    label={taskPlan?.status_task_plan == '1' ? 'Concluído' : (taskPlan?.date_task_plan && new Date() > new Date(taskPlan?.date_task_plan) ? 'Em Atrazo' : 'Em Andamento') } 
                                    size="small" 
                                    color={taskPlan?.status_task_plan == '1' ? 'success' : (taskPlan?.date_task_plan && new Date() > new Date(taskPlan?.date_task_plan) ? 'error' : 'secondary')} />
                                </TableCell>
                            </TableRow>
                            ))) : (
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                Nenhum item encontrado 
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>     
            </DialogContent>  
        <Divider/>
        <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Fechar</Button>
        </DialogActions>
    </Dialog>   
    );
});

